"use client";

import { Alert, AlertIcon, Box, Container, Flex, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";

export default function StatsGridWithImage() {
    return (
        <Box bg={"gray.800"} position={"relative"}>
            <Container maxW={"7xl"} zIndex={10} position={"relative"}>
                <Alert status={"warning"}>
                    <AlertIcon />
                    Because of the recent (TOR) DNS takedown incident we changed our subscription policy. From now on content viewers has to deposit one time payment via crypto and
                    they will be given access for lifetime. Token based tips system is removed now. We are not accepting new users through registration page. For new registration
                    please contact admins.
                </Alert>
                <Stack direction={{ base: "column", lg: "row" }}>
                    <Stack flex={1} color={"gray.400"} justify={{ lg: "center" }} py={{ base: 4, md: 20, xl: 60 }}>
                        <Box mb={{ base: 8, md: 20 }}>
                            <Text fontFamily={"heading"} fontWeight={700} textTransform={"uppercase"} mb={3} fontSize={"xl"} color={"gray.500"}>
                                About Xero-CP
                            </Text>
                            <Heading color={"white"} mb={5} fontSize={{ base: "3xl", md: "5xl" }}>
                                Giving services since 2015
                            </Heading>
                            <Text fontSize={"xl"} color={"gray.400"}>
                                We are an independent live streaming mediators. We offer a platform for porn enthusiast to create and share their videos in a secure (TOR)
                                environment. We do not apply any limitations on video content so you are free to share and stream any kind of pornographic material. We also do not
                                know or store the users data.
                            </Text>
                        </Box>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            {stats.map((stat) => (
                                <Box key={stat.title}>
                                    <Text fontFamily={"heading"} fontSize={"3xl"} color={"white"} mb={3}>
                                        {stat.title}
                                    </Text>
                                    <Text fontSize={"xl"} color={"gray.400"}>
                                        {stat.content}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Stack>
                    <Flex flex={1} />
                </Stack>
            </Container>
        </Box>
    );
}

const StatsText = ({ children }) => (
    <Text as={"span"} fontWeight={700} color={"white"}>
        {children}
    </Text>
);

const stats = [
    {
        title: "10+",
        content: (
            <>
                <StatsText>Categories</StatsText> of kinks for live streaming   
            </>
        ),
    },
    {
        title: "24/7",
        content: (
            <>
                <StatsText>Always available</StatsText> streamers and private rooms
            </>
        ),
    },
    {
        title: "100%",
        content: (
            <>
                <StatsText>Fully secured</StatsText> transactions and data
            </>
        ),
    },
    {
        title: "1000+",
        content: (
            <>
                <StatsText>Growing happy customers</StatsText> from all over the world
            </>
        ),
    },
];
