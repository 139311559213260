"use client";

import { Alert, AlertIcon, Box, Button, Divider, Heading, List, ListIcon, ListItem, Stack, Text, useColorModeValue } from "@chakra-ui/react";

import { FaCheckCircle } from "react-icons/fa";
import {useToast} from "@chakra-ui/react";

const options = [
    { id: 1, desc: "Live Streaming" },
    { id: 2, desc: "Videos Collections" },
    { id: 3, desc: "Private Rooms" },
];

const PackageTier = ({ title, options, typePlan, checked = true }) => {
    const colorTextLight = checked ? "white" : "purple.600";
    const bgColorLight = checked ? "purple.400" : "gray.300";

    const colorTextDark = checked ? "white" : "purple.500";
    const bgColorDark = checked ? "purple.400" : "gray.300";
    const toast = useToast({
        position: "top",
    });

    const onEnable = () => {
        toast({
            title: "Payment required",
            description: "Send the payment to bitcoin address and contact admin to enable the category.",
            status: "warning",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Stack
            p={3}
            py={3}
            justifyContent={{
                base: "flex-start",
                md: "space-around",
            }}
            direction={{
                base: "column",
                md: "row",
            }}
            alignItems={{ md: "center" }}
        >
            <Heading size={"xl"}>{title}</Heading>
            <List spacing={3} textAlign="start">
                {options.map((desc, id) => (
                    <ListItem key={desc.id}>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        {desc.desc}
                    </ListItem>
                ))}
            </List>
            <Heading size={"xl"}>{typePlan}</Heading>
            <Stack>
                <Button size="md" color={useColorModeValue(colorTextLight, colorTextDark)} bgColor={useColorModeValue(bgColorLight, bgColorDark)} onClick={onEnable}>
                    Enable Now
                </Button>Plus
            </Stack>
        </Stack>
    );
};
const Dashboard = () => {

    if (localStorage.token !== "logged-in") {
        window.location.href = "/login";
        return <Alert status="error">
            <AlertIcon />
            You are not logged in. Please login to access the dashboard.
        </Alert>
    }

    return (
        <Box py={6} px={5} width="full">
            <Alert status="error">
                <AlertIcon />
                You are not subscribed to any categories. Please subscribe to any category to get access to the live streams and private rooms.
            </Alert>
            <Stack spacing={4} width={"100%"} direction={"column"}>
                <Stack
                    p={5}
                    alignItems={"center"}
                    justifyContent={{
                        base: "flex-start",
                        md: "space-around",
                    }}
                    direction={{
                        base: "column",
                        md: "row",
                    }}
                >
                    <Stack
                        width={{
                            base: "100%",
                            md: "40%",
                        }}
                        textAlign={"center"}
                    >
                        <Heading size={"lg"}>
                            You aren't subscribed to any <Text color="purple.400">categories</Text>
                        </Heading>
                    </Stack>
                    <Stack
                        width={{
                            base: "100%",
                            md: "60%",
                        }}
                    >
                        <Text textAlign={"center"}>
                            Welcome to XERO-CP. You can subscribe to any category to get access to the live streams and private rooms. Choose a category and make payment on the
                            following bitcoin address <kbd>8291023882020292828191010019282828192</kbd> and then contact to the admins to enable the categories for you. Once a
                            category is enable you will be able to see live streams and join private rooms.
                        </Text>
                    </Stack>
                </Stack>
                <Divider />
                <PackageTier title={"CP"} typePlan="$300.00" options={options} />
                <Divider />
                <PackageTier title={"Mom-Son"} typePlan="$250.00" options={options} />
                <Divider />
                <PackageTier title={"Dad-Daughter"} typePlan="$250.00" options={options} />
                <Divider />
                <PackageTier title={"Voyeur"} typePlan="$150.00" options={options} />
                <Divider />
                <PackageTier title={"IP Cams"} typePlan="$150.00" options={options} />
                <Divider />
                <PackageTier title={"RP"} typePlan="$300.00" options={options} />
                <Divider />
                <PackageTier title={"Zoo"} typePlan="$250.00" options={options} />
                <Divider />
                <PackageTier title={"Bro-Sis"} typePlan="$250.00" options={options} />
                <Divider />
                <PackageTier title={"Teens"} typePlan="$350.00" options={options} />
                <Divider />
                <PackageTier title={"Forced"} typePlan="$250.00" options={options} />
                <Alert status="warning">
                <AlertIcon />
                Due to recent (TOR) DNS takedown incident we changed our subscription policy. From now on content viewers has to deposit one time payment via crypto and they will be given access for lifetime. Token based tips system is removed now. We are not accepting new users through registration page. For new registration please contact admins.
            </Alert>
            </Stack>
        </Box>
    );
};

export default Dashboard;
