import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import Features from './components/Features';
import Home from './components/Home';
import Login from './components/Login';
import Navbar from './components/Navbar';
import React from 'react';

const HomeRoute = () => {
  return <>
    <Home />
    <Features />
  </>;
}

function App() {
  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route element={<HomeRoute />} path="/" />
          <Route element={<Login />} path="/login" />
          <Route element={<Dashboard />} path="/dash" />
          <Route element={<Contact />} path="/contact" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
