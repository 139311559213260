import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";

export default function Login() {
    const DEFAULT_USERNAME = "u1771";
    const DEFAULT_PASSWORD = "x66790x";

    if (localStorage.token === 'logged-in') {
        window.location.href = "/dash";
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;
        if (username === DEFAULT_USERNAME && password === DEFAULT_PASSWORD) {
            localStorage.user = DEFAULT_USERNAME;
            localStorage.token = 'logged-in';
            window.location.href = "/dash";
        } else {
            alert("Invalid username or password");
        };
    };

    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Sign in to your account</Heading>
                    <Text fontSize={"lg"} color={"gray.600"}>
                        If you are a new user please contact the admin to get your account.
                    </Text>
                </Stack>
                <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8} as="form" onSubmit={onSubmit}>
                    <Stack spacing={4}>
                        <FormControl id="username">
                            <FormLabel>Username</FormLabel>
                            <Input type="username" name="username" required/>
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" name="password" required/>
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack direction={{ base: "column", sm: "row" }} align={"start"} justify={"space-between"}>
                                <Checkbox>Remember me</Checkbox>
                                <Text color={"blue.400"}>Forgot password?</Text>
                            </Stack>
                            <Button
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                    bg: "blue.500",
                                }}
                                type="submit"
                            >
                                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}
