"use client";

import { Box, Button, Flex, HStack, Heading, IconButton, Stack, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

const NavLink = (props) => {
    const { children, href } = props;

    return (
        <Box
            as="a"
            px={2}
            py={1}
            rounded={"md"}
            _hover={{
                textDecoration: "none",
                bg: useColorModeValue("gray.200", "gray.700"),
            }}
            href={href}
        >
            {children}
        </Box>
    );
};

export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const isLogged = localStorage.token === "logged-in";

    const onLogout = () => {
        localStorage.removeItem("token");
        window.location.href = "/login";
    };

    return (
        <>
            <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
                <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
                    <HStack spacing={8} alignItems={"center"}>
                        <Box as="a" href="/">
                            <Heading>XERO-CP</Heading>
                        </Box>
                        <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
                            <NavLink href="/contact">Contact</NavLink>
                            <NavLink href="/dash" hidden={!isLogged}>
                                Dashboard
                            </NavLink>
                        </HStack>
                    </HStack>
                    <Flex alignItems={"center"}>
                        <Stack as={"nav"} spacing={4} direction={"row"} display={{ base: "none", md: "flex" }}>
                            <Button as={"a"} href="/login" hidden={isLogged}>
                                Login
                            </Button>
                            <Button as={"a"} hidden={!isLogged} onClick={onLogout}>
                                Logout
                            </Button>
                        </Stack>
                    </Flex>
                    <IconButton
                        size={"md"}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={"Open Menu"}
                        display={{ md: "none" }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: "none" }}>
                        <Stack as={"nav"} spacing={4}>
                            <NavLink href="/contact">Contact</NavLink>
                            <NavLink href="/dash" hidden={!isLogged}>
                                Dashboard
                            </NavLink>
                            <Button as={"a"} href="/login" hidden={isLogged}>
                                Login
                            </Button>
                            <Button as={"a"} hidden={!isLogged} onClick={onLogout}>
                                Logout
                            </Button>
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}
